import React, { useEffect, useState } from 'react'
import { graphql, Script, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import RummyPage from '../../components/Games/Cards/Rummy/Rummy'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  RUMMY_HINDI,
  RUMMY as RUMMY_LINK,
} from '../../components/internal-links'
import { LATEST_RC } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateRummyReviewSchemaAndroid,
  generateRummyReviewSchemaIos,
  generateRummyWebPageSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { rummyOneLinksRummyPage } from '../../components/one-links'
import RenderHTML from '../../components/Poker/PokerVarientPages/RenderHTML'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const Rummy: React.FC = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        allGhostPost(
          filter: { slug: { eq: "play-rummy-online-for-real-money" } }
        ) {
          nodes {
            html
            title
            meta_title
            meta_description
          }
        }
      }
    `
  )

  const title = data.allGhostPost.nodes[0]?.title
  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  const breadcrumbs: Breadcrumb[] = [
    {
      title: HOMEPAGE_BREADCRUMB_TITLE,
      url: '/',
    },
    {
      title: title,
      url: RUMMY_LINK,
    },
  ]

  const SITE_URL = data.site.siteMetadata.siteUrl

  const [rummyFaqsSt, setRummyFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ rummyFaqs }) =>
      setRummyFaqsSt(rummyFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={LATEST_RC}
      showRummyForm
      rummyOneLink={rummyOneLinksRummyPage}
      customLogo
      langPost={[
        {
          languageName: 'english',
          pageSlug: `${SITE_URL}${RUMMY_LINK}`,
        },
        {
          languageName: 'hindi',
          pageSlug: `${SITE_URL}${RUMMY_HINDI}`,
        },
      ]}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
        <link
          rel="alternate"
          href={`${SITE_URL}${RUMMY_LINK}`}
          hrefLang="en-in"
        />
        <link
          rel="alternate"
          href={`${SITE_URL}${RUMMY_LINK}`}
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href={`${SITE_URL}${RUMMY_HINDI}`}
          hrefLang="hi-in"
        />
        <script>
          {`
            document.addEventListener('DOMContentLoaded', () => {
              /** init gtm after 1500 seconds - this could be adjusted */
              setTimeout(initGTM, 1500);
            });
            document.addEventListener('scroll', initGTMOnEvent);
            document.addEventListener('mousemove', initGTMOnEvent);
            document.addEventListener('touchstart', initGTMOnEvent);
            function initGTMOnEvent(event) {
              initGTM();
              event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
            }
            function initGTM() {
              if (window.gtmDidInit) {
                return false;
              }
              window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.async = true;
              // ensure PageViews is always tracked (on script load)
              script.onload = () => {
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'AW-718511341');
              };
              script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-718511341';
              document.head.appendChild(script);
            }
          `}
        </script>
      </Helmet>
      <SEO
        title={metaTitle}
        description={metaDescription}
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaRummyAndroid={generateRummyReviewSchemaAndroid()}
        reviewSchemaRummyIos={generateRummyReviewSchemaIos()}
        faqSchema={generateFaqSchema(rummyFaqsSt)}
        webPageSchema={generateRummyWebPageSchema()}
        hideHrefLang
      />
      <RummyPage
        htmlContent={<RenderHTML html={data.allGhostPost.nodes[0]?.html} />}
        mainBannerTitle={title}
      />
    </Layout>
  )
}

export default Rummy
